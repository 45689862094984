export default function initFilter() {
    $(document).ready(function() {
        $('.js-project-category-filter-select').on('change', function() {
            this.value && (window.location.href = this.value);
            $('.js-project-phase-filter-select').prop('disabled', true);
        });

        $('.js-project-phase-filter-select').prop('disabled', !$('.js-project-category-filter-select').val());

        $('.js-project-phase-filter-select').on('change', function() {
            if (!this.value) {
                return;
            }

            if ('URLSearchParams' in window) {
                var searchParams = new URLSearchParams(window.location.search);
                searchParams.set('phase', this.value);
                window.location.search = searchParams.toString();
            }
        });

        $('.js-search-form').submit(function() {
            var $phaseHidden = $('input[type="hidden"][name="phase"]');

            if (!$phaseHidden.length) {
                $phaseHidden = $('<input>', {
                    name: 'phase',
                    type: 'hidden',
                    value: this.value,
                });

                $('.js-search-form').prepend($phaseHidden);
            }

            $phaseHidden.val($('.js-project-phase-filter-select').val());
        });

        $('.js-filter-clear').on('click', function(e) {
            e.preventDefault();
            $('.filter-form-wrapper select').val('');
            $('.filter-form-wrapper .form-control').val('');
        });
    });
}

initFilter();
