let maxLevel = 0; // szűrés miatt kell

function getChild(tr) {
    const $table = $(tr).closest('table');
    return $table.find('tr[data-parent="' + tr.id?.replace('term_', '') + '"]')
}

function getDescendants($descendants, el) {
    let $child = getChild(el);

    if ($child.length) {
        $descendants = $descendants.add($child);

        $child.each(function() {
            $descendants = getDescendants($descendants, this);
        });
    }

    return $descendants;
}

function getParent(tr) {
    const $tr = $(tr);
    const $table = $tr.closest('table');
    return $table.find('tr[id="term_' + $tr.attr('data-parent') + '"]');
}

function getParents(tr, $parents = $()) {
    const $parent = getParent($(tr).get(0));
    $parents = $parents.add($parent);

    if ($parent.length) {
        return getParents($parent, $parents);
    }
    else {
        return $parents;
    }
}

function setLevels(tr, level) {
    const $parentTr = getParent(tr);
    const $tr = $(tr);

    if ($parentTr.length) {
        level = setLevels($parentTr, ++level);
    }

    if (!$tr.is('[class*="level-"')) {
        $tr.addClass('level-' + level);
    }

    maxLevel = Math.max(maxLevel, level);

    return level;
}

function setState(tr, opened = true) {
    $(tr).data('state-opened', opened);
}

function getState(tr) {
    return $(tr).data('state-opened');
}

function setTreeClasses(tr) {
    const $tr = $(tr);
    switch (getChild(tr).length) {
        case 0:
            $tr.addClass('leaf');
            break;
        case 1:
            $tr.addClass('has-one-child');
            break;
    }
}

function filterList() {
    var $filter = $('.js-table-filter-input');
    var filterText = $filter.val().toLowerCase();
    var FILTERTEXT_MIN_LENGTH = 2;

    if ( FILTERTEXT_MIN_LENGTH <= filterText.length ) {
        $('.js-table-filter-clearButton').removeClass('d-none');
        $('.js-table-filtered tbody tr')
            .removeClass('js-filtered-hidden filtered-hidden')
            .filter((i, tr) => {
                const hasFilterText = -1 !== $(tr).text().toLowerCase().indexOf(filterText);
                const $descendants = getDescendants($(), tr);
                const hasFilteredDescendant = $descendants.filter((i, descendant) => !$(descendant).hasClass('js-filtered-hidden')).length; // van olyan gyerek, ami bekerül a szűrt listába

                $(tr).data('has-filter-text', hasFilterText);

                // leaf vagy nem megkülönböztetése
                return !hasFilterText && !$descendants.length || !hasFilterText && $descendants.length && !hasFilteredDescendant;
            })
            .addClass('js-filtered-hidden filtered-hidden');

        // iterálni kell a szülőkön, hogy lássuk, összesen hány rejtett elem van
        for (let i = maxLevel; 0 < i; i--) {
            $('.js-table-filtered tbody tr:not(.leaf)')
                .filter(function (i, notLeaf) {
                    const $descendants = getDescendants($(), notLeaf);

                    // csak akkor szabad elrejteni a szülőt, ha nincs megjelenő gyereke, ill. maga nem kerül be a szűrtek közé
                    return $descendants.length && $descendants.filter((i, descendant) => $(descendant).hasClass('js-filtered-hidden')).length == $descendants.length && !$(notLeaf).data('has-filter-text');
                })
                .addClass('js-filtered-hidden filtered-hidden');
        }

        // szűréskor kinyitunk mindent
        if ($('.js-filtered-hidden').length) {
            $('.js-table-catalogue tr').each(function(i, tr) {
                const $tr = $(tr);

                $tr.removeClass('closed d-none');
                setState(tr, true);
            });
        }
    }
    else {
        $('.js-table-filter-clearButton').addClass('d-none');
        $('.js-filtered-hidden').removeClass('js-filtered-hidden filtered-hidden');
        return false;
    }
}

function showWholeList(e) {
    $('.js-table-filter-input')
        .val('')
        .focus();
    filterList();
}

export default function initCatalogue() {
    $(document).ready(function() {
        // class-ok data-k elhelyezése
        $('.js-table-catalogue tbody tr').each(function() {
            setLevels(this, 0);
            setState(this);
            setTreeClasses(this);
        });

        // csukogatás
        $('.js-table-catalogue tr').on('click', function(e) {
            e.stopPropagation();
            const $this = $(this);

            // reszponzív nézetben ne legyen akkordion
            if ($(window).width() < 992 || $this.hasClass('has-one-child')) {
                return false;
            }

            const isOpened = getState(this);
            let $descendants = getDescendants($(), this);

            setState(this, !isOpened);
            $this.toggleClass('closed', isOpened);

            if (isOpened) {
                $descendants.addClass('d-none');
            }
            else {
                $descendants.each(function(i, descendant) {
                    const $parents = getParents(descendant);

                    if ($parents.filter((i, parent) => getState(parent)).length == $parents.length) {
                        $(descendant).removeClass('d-none');
                    }
                });
            }
        });

        // filter mező és -gomb
        if ($('.js-table-filter-input').length) {
            $(document).on('keyup', '.js-table-filter-input', filterList);
            $(document).on('click', '.js-table-filter-clearButton', showWholeList);
            filterList(); // betöltéskor is szűrjünk, ha van szűrőfeltétel
        }
    });
}

initCatalogue();
