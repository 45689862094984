// cookie-layer
export default function initCookieConsent($) {

    /**
     * Cookie name
     * @type {string}
     */
    const cookieName = 'cookieConsentAccepted';

    /**
     * Sets cookie
     *
     * @param {string} cname
     * @param {string|number} cvalue
     * @param {number} exdays
     */
    function setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; path=/; " + expires;
    };
    /**
     * Gets cookie value
     *
     * @param {string} cname
     * @return {string}
     */
    function getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(var i=0; i<ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
        }
        return "";
    };

    const CookieConsent = {
        $el: $(),
        $button: $(),
        accept: function () {
            setCookie(cookieName, new Date().toUTCString(), 365 * 2);
            this.$el.addClass('d-none');
        },
        canRun: function () {
            const cookie = getCookie(cookieName);

            return (cookie === '');
        },
        decline: function () {
            this.$el.addClass('d-none');
        },
        bindEvents: function () {
            this.$button.click($.proxy(this.accept, this));
            this.$declineButton.click($.proxy(this.decline, this));
        },
        init: function () {
            this.$el = $('.js-cookie-consent');
            this.$button = $('.js-cookie-consent-button');
            this.$declineButton = $('.js-cookie-decline-button');

            if (this.canRun()) {
                this.$el.removeClass('d-none');
                this.bindEvents();
            }
        }
    }

    CookieConsent.init();
};

$(document).ready(function() {
    initCookieConsent($);
});
