import DataTable from 'datatables.net-bs5';
import 'datatables.net-fixedheader-bs5';
import language from 'datatables.net-plugins/i18n/hu.mjs';
import { data } from './bimGlossaryData';

function initBIMGlossary() {
    $(function() {
        if ($('#bimGlossary').length) {
            let table = new DataTable('#bimGlossary', {
                columns: [
                    { visible: false },
                    { render: renderDescription },
                    { className: 'glossary-abbr' },
                    { visible: false },
                    { render: renderDescription },
                    { className: 'glossary-abbr' },
                    { visible: false, className: 'level' },
                    { visible: false, className: 'projectPhases' },
                ],
                data: data,
                ordering:  false,
                language: {
                    ...language,
                    paginate: {
                        previous: "&lt;",
                        next:     "&gt",
                    }
                },
                fixedHeader: true,
                autoWidth: false, // különben mobil nézetben betöltve, majd desktop nézetre váltva elcsúsztak a fixed header cellák
                dom: '<"bg-secondary text-white glossary-filter-text mb-5"f>tp', // https://datatables.net/reference/option/dom#Examples

                // reszpo
                createdRow: function( row, data, dataIndex ) {
                    // Set the data-status attribute, and add a class
                    $(row).find('td').each(function() {
                        const $td = $(this);

                        $td.attr(
                            'data-cell-header',
                            $('#bimGlossary').attr('data-header-title-column-' + $td.index())
                        );
                    });
                },
            });

            // filter indítása
            $('.js-filter-table input').on('change', function() {filterTable(table)});
            filterTable(table);

            // lapozáskor felgörgetünk
            $('#bimGlossary').on( 'page.dt', function () {
                setTimeout(function() {
                    $(window).scrollTop($('#bimGlossary').offset().top)
                }, 50);
            } );
        }
    });
}

// leírás cella tartalmának kiegészítése
function renderDescription(data, type, row, meta) {
    return type === 'display' ?
        '<div class="glossary-description-title">' + row[0] + '</div>' + data :
        data;
}

// szűrés checkbox-okkal
function filterTable(table) {
    //build a regex filter string with an or(|) condition
    var levels = $('.js-filter-table-levels input').filter(':checked').map(function() {
        return this.value;
    }).get().join('|');

    var projectPhases = $('.js-filter-table-projectPhases input').filter(':checked').map(function() {
        return this.value;
    }).get().join('|');

    table
        .columns('.level').search(levels, true, false)
        .columns('.projectPhases').search(projectPhases, true, false)
        .draw();
}

initBIMGlossary();
