export let data = [
    [
        "Common Data Environment",
        "An \"agreed source of Information for any given project or Asset, for collecting, managing and disseminating each Information Container through a managed process\" ISO 19650-1(3.3.15). A Common Data Environment (CDE) includes a 'CDE solution' and a 'CDE workflow' This CDE Workflow organises the flow and management of information across the whole Life Cycle of an Asset",
        "CDE",
        "Közös Adatkörnyezet",
        "Kizárólagos központi információforrás, ami összegyűjti, menedzseli és terjeszti a releváns és elfogadott projekt dokumentációkat a szakági csapatok között egy szervezett folyamat során. A Közös Adatkörnyezetet (CDE) jellemzően egy Dokumentum Menedzsment Rendszer szolgálja ki, ami megkönnyíti az adatok/információ megosztását a Projekt Résztvevők között.",
        "CDE",
        "default",
        "preparation"
    ],
    [
        "Level of Development",
        "A BIM metric to identify what information to include in a model during the design and construction process (also refer to Model Progression Specifications). Please note that the LOD abbreviation refers to multiple terms, definitions, and numbering systems even within the same country.",
        "LOD",
        "Kidolgozottsági szint",
        "Egy BIM mérőszám, ami alapján azonosítani tudjuk, hogy milyen információt, milyen részletezettségi szinten kell társítanunk egy modellhez a tervezés és kivitelezés folyamán (lásd még: Modell fejlődési specifikáció). Fontos megjegyzés, hogy a LOD rövidítés más fogalmakhoz, definíciókhoz és számozási rendszerekhez is társítható, akár országon belül is.",
        "LOD",
        "engineering",
        "investment"
    ],
    [
        "Common Data Environment",
        "An \"agreed source of Information for any given project or Asset, for collecting, managing and disseminating each Information Container through a managed process\" ISO 19650-1(3.3.15). A Common Data Environment (CDE) includes a 'CDE solution' and a 'CDE workflow' This CDE Workflow organises the flow and management of information across the whole Life Cycle of an Asset",
        "CDE",
        "Közös Adatkörnyezet",
        "Kizárólagos központi információforrás, ami összegyűjti, menedzseli és terjeszti a releváns és elfogadott projekt dokumentációkat a szakági csapatok között egy szervezett folyamat során. A Közös Adatkörnyezetet (CDE) jellemzően egy Dokumentum Menedzsment Rendszer szolgálja ki, ami megkönnyíti az adatok/információ megosztását a Projekt Résztvevők között.",
        "CDE",
        "professional",
        "planning"
    ],
    [
        "Level of Development",
        "A BIM metric to identify what information to include in a model during the design and construction process (also refer to Model Progression Specifications). Please note that the LOD abbreviation refers to multiple terms, definitions, and numbering systems even within the same country.",
        "LOD",
        "Kidolgozottsági szint",
        "Egy BIM mérőszám, ami alapján azonosítani tudjuk, hogy milyen információt, milyen részletezettségi szinten kell társítanunk egy modellhez a tervezés és kivitelezés folyamán (lásd még: Modell fejlődési specifikáció). Fontos megjegyzés, hogy a LOD rövidítés más fogalmakhoz, definíciókhoz és számozási rendszerekhez is társítható, akár országon belül is.",
        "LOD",
        "engineering",
        "implementation"
    ],
    [
        "Common Data Environment",
        "An \"agreed source of Information for any given project or Asset, for collecting, managing and disseminating each Information Container through a managed process\" ISO 19650-1(3.3.15). A Common Data Environment (CDE) includes a 'CDE solution' and a 'CDE workflow' This CDE Workflow organises the flow and management of information across the whole Life Cycle of an Asset",
        "CDE",
        "Közös Adatkörnyezet",
        "Kizárólagos központi információforrás, ami összegyűjti, menedzseli és terjeszti a releváns és elfogadott projekt dokumentációkat a szakági csapatok között egy szervezett folyamat során. A Közös Adatkörnyezetet (CDE) jellemzően egy Dokumentum Menedzsment Rendszer szolgálja ki, ami megkönnyíti az adatok/információ megosztását a Projekt Résztvevők között.",
        "CDE",
        "default",
        "producers"
    ],
    [
        "Level of Development",
        "A BIM metric to identify what information to include in a model during the design and construction process (also refer to Model Progression Specifications). Please note that the LOD abbreviation refers to multiple terms, definitions, and numbering systems even within the same country.",
        "LOD",
        "Kidolgozottsági szint",
        "Egy BIM mérőszám, ami alapján azonosítani tudjuk, hogy milyen információt, milyen részletezettségi szinten kell társítanunk egy modellhez a tervezés és kivitelezés folyamán (lásd még: Modell fejlődési specifikáció). Fontos megjegyzés, hogy a LOD rövidítés más fogalmakhoz, definíciókhoz és számozási rendszerekhez is társítható, akár országon belül is.",
        "LOD",
        "engineering",
        "producers"
    ],
    [
        "Common Data Environment",
        "An \"agreed source of Information for any given project or Asset, for collecting, managing and disseminating each Information Container through a managed process\" ISO 19650-1(3.3.15). A Common Data Environment (CDE) includes a 'CDE solution' and a 'CDE workflow' This CDE Workflow organises the flow and management of information across the whole Life Cycle of an Asset",
        "CDE",
        "Közös Adatkörnyezet",
        "Kizárólagos központi információforrás, ami összegyűjti, menedzseli és terjeszti a releváns és elfogadott projekt dokumentációkat a szakági csapatok között egy szervezett folyamat során. A Közös Adatkörnyezetet (CDE) jellemzően egy Dokumentum Menedzsment Rendszer szolgálja ki, ami megkönnyíti az adatok/információ megosztását a Projekt Résztvevők között.",
        "CDE",
        "professional",
        "producers"
    ],
    [
        "Level of Development",
        "A BIM metric to identify what information to include in a model during the design and construction process (also refer to Model Progression Specifications). Please note that the LOD abbreviation refers to multiple terms, definitions, and numbering systems even within the same country.",
        "LOD",
        "Kidolgozottsági szint",
        "Egy BIM mérőszám, ami alapján azonosítani tudjuk, hogy milyen információt, milyen részletezettségi szinten kell társítanunk egy modellhez a tervezés és kivitelezés folyamán (lásd még: Modell fejlődési specifikáció). Fontos megjegyzés, hogy a LOD rövidítés más fogalmakhoz, definíciókhoz és számozási rendszerekhez is társítható, akár országon belül is.",
        "LOD",
        "professional",
        "preparation"
    ],
    [
        "Common Data Environment",
        "An \"agreed source of Information for any given project or Asset, for collecting, managing and disseminating each Information Container through a managed process\" ISO 19650-1(3.3.15). A Common Data Environment (CDE) includes a 'CDE solution' and a 'CDE workflow' This CDE Workflow organises the flow and management of information across the whole Life Cycle of an Asset",
        "CDE",
        "Közös Adatkörnyezet",
        "Kizárólagos központi információforrás, ami összegyűjti, menedzseli és terjeszti a releváns és elfogadott projekt dokumentációkat a szakági csapatok között egy szervezett folyamat során. A Közös Adatkörnyezetet (CDE) jellemzően egy Dokumentum Menedzsment Rendszer szolgálja ki, ami megkönnyíti az adatok/információ megosztását a Projekt Résztvevők között.",
        "CDE",
        "professional",
        "preparation"
    ],
    [
        "Level of Development",
        "A BIM metric to identify what information to include in a model during the design and construction process (also refer to Model Progression Specifications). Please note that the LOD abbreviation refers to multiple terms, definitions, and numbering systems even within the same country.",
        "LOD",
        "Kidolgozottsági szint",
        "Egy BIM mérőszám, ami alapján azonosítani tudjuk, hogy milyen információt, milyen részletezettségi szinten kell társítanunk egy modellhez a tervezés és kivitelezés folyamán (lásd még: Modell fejlődési specifikáció). Fontos megjegyzés, hogy a LOD rövidítés más fogalmakhoz, definíciókhoz és számozási rendszerekhez is társítható, akár országon belül is.",
        "LOD",
        "engineering",
        "investment"
    ],
    [
        "Common Data Environment",
        "An \"agreed source of Information for any given project or Asset, for collecting, managing and disseminating each Information Container through a managed process\" ISO 19650-1(3.3.15). A Common Data Environment (CDE) includes a 'CDE solution' and a 'CDE workflow' This CDE Workflow organises the flow and management of information across the whole Life Cycle of an Asset",
        "CDE",
        "Közös Adatkörnyezet",
        "Kizárólagos központi információforrás, ami összegyűjti, menedzseli és terjeszti a releváns és elfogadott projekt dokumentációkat a szakági csapatok között egy szervezett folyamat során. A Közös Adatkörnyezetet (CDE) jellemzően egy Dokumentum Menedzsment Rendszer szolgálja ki, ami megkönnyíti az adatok/információ megosztását a Projekt Résztvevők között.",
        "CDE",
        "default",
        "planning"
    ],
    [
        "Level of Development",
        "A BIM metric to identify what information to include in a model during the design and construction process (also refer to Model Progression Specifications). Please note that the LOD abbreviation refers to multiple terms, definitions, and numbering systems even within the same country.",
        "LOD",
        "Kidolgozottsági szint",
        "Egy BIM mérőszám, ami alapján azonosítani tudjuk, hogy milyen információt, milyen részletezettségi szinten kell társítanunk egy modellhez a tervezés és kivitelezés folyamán (lásd még: Modell fejlődési specifikáció). Fontos megjegyzés, hogy a LOD rövidítés más fogalmakhoz, definíciókhoz és számozási rendszerekhez is társítható, akár országon belül is.",
        "LOD",
        "engineering",
        "investment"
    ]
];
