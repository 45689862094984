function initResourceLogos () {
    let logoContainer = document.querySelector('.project-resource-logos');

    if ( ! logoContainer) {
        return;
    }

    logoContainer.style.width = logoContainer.offsetWidth;
}

document.addEventListener('DOMContentLoaded', initResourceLogos);
